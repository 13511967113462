import { getPath } from '../../../routing/get-path'
import { currentBrand } from '../../../config'
import { availableRentalTypes } from '../../../config/markets'

import { useNavBreakpoint } from './use-nav-breakpoint'

type NavBarItemType =
  | { href: string; label_i18n: string }
  | { href: string; label_i18n: string; badge_i18n?: string }

const NAV_BAR_ITEMS = {
  HOMES: { href: '/find-home', label_i18n: 'homes' },
  VACATION_HOMES: { href: getPath('findVacation'), label_i18n: 'vacation_homes' },
  FIND_TENANT: { href: getPath('findTenant'), label_i18n: 'find_tenant' },
  LEASES: { href: getPath('leases'), label_i18n: 'leases' },
  HOW_IT_WORKS: { href: getPath('howItWorks'), label_i18n: 'how_it_works' },
}

export function useNavBarItems(): NavBarItemType[] {
  const hasMultipleRentalTypes = availableRentalTypes.length > 1

  const { isCollapsed } = useNavBreakpoint()

  if (isCollapsed) {
    return []
  }

  if (currentBrand === 'qasa_france') {
    return [NAV_BAR_ITEMS.LEASES]
  }

  return [
    NAV_BAR_ITEMS.HOMES,
    NAV_BAR_ITEMS.FIND_TENANT,
    ...(hasMultipleRentalTypes ? [NAV_BAR_ITEMS.VACATION_HOMES] : []),
    NAV_BAR_ITEMS.HOW_IT_WORKS,
  ]
}
